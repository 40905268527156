import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BackgroundsService {

	constructor() { }

	private _backsSize = 29;
	private exclude = [1, 8];

	private _backgrounds = new Array(this._backsSize)
		.fill(1)
		.map((filler: number, i: number) => !this.exclude.includes(i + 1) ? `assets/backs/jw_${i + 1}.jpg` : "")
		.filter((back: string) => back.length > 0);

	getAll(): Observable<string[]> {
		return of(this._backgrounds);
	}

}

