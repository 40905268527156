import { Component } from '@angular/core';

@Component({
	selector: 'jw-root',
	templateUrl: 'app.html',
})
export class AppComponent {

	title = 'jerzywypych.com';
	showDetails = false;

	randomString = atob(new Date().toDateString()) + Math.random() * 1000;

	showContact() {
		this.showDetails = !this.showDetails;
	}

	sendEmail() {
		window.location.href = "mailto:jerzywypych@gmail.com";
	}

}