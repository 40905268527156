import { Component, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';
import { shuffle } from 'lodash';

import { BackgroundsService } from '../../services/backgrounds.service';

@Component({
	selector: 'jw-background-carousel',
	templateUrl: 'background-carousel.html'
})
export class BackgroundCarouselComponent implements AfterViewInit, OnDestroy {
	@ViewChild('carousel', { static: false }) carousel: ElementRef;

	constructor(private backgroundService: BackgroundsService) { }

	backgrounds: string[] = [];
	timeline: gsap.core.Timeline;
	currentIndex = 0;
	backgroundElements: HTMLCollection;

	private _backgrounds$ = this.backgroundService.getAll().subscribe(backs => {
		this.backgrounds = shuffle(backs);
	});

	ngAfterViewInit() {
		this.backgroundElements = this.carousel.nativeElement.children;
		const animationLength = 3;
		const delay = 3;

		this.timeline = gsap.timeline({ repeat: -1, delay: animationLength });

		// Initial fade-in for the first image
		gsap.to(this.backgroundElements.item(0), { duration: animationLength, autoAlpha: 1 });

		// Sequential animation for automatic transitions
		for (let i = 1; i < this.backgroundElements.length; i++) {
			this.timeline.to(this.backgroundElements.item(i), { duration: animationLength, autoAlpha: 1, delay });
			this.timeline.to(this.backgroundElements.item(i - 1), { duration: animationLength, autoAlpha: 0 });
		}
	}

	nextSlide() {
		const current = this.backgroundElements.item(this.currentIndex);
		this.currentIndex = (this.currentIndex + 1) % this.backgrounds.length;
		const next = this.backgroundElements.item(this.currentIndex);

		// Animate fade out of current and fade in of next
		gsap.to(current, { duration: 1, autoAlpha: 0 });
		gsap.to(next, { duration: 1, autoAlpha: 1 });
	}

	prevSlide() {
		const current = this.backgroundElements.item(this.currentIndex);
		this.currentIndex = (this.currentIndex - 1 + this.backgrounds.length) % this.backgrounds.length;
		const prev = this.backgroundElements.item(this.currentIndex);

		// Animate fade out of current and fade in of previous
		gsap.to(current, { duration: 1, autoAlpha: 0 });
		gsap.to(prev, { duration: 1, autoAlpha: 1 });
	}

	ngOnDestroy() {
		if (this._backgrounds$) {
			this._backgrounds$.unsubscribe();
		}
	}

	getBack(backSrc: string): string {
		return `url(${backSrc})`;
	}
}
